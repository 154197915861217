"use client";

import { cva } from "class-variance-authority";
import { User } from "lucide-react";
import { Avatar, AvatarImage } from "@shared-components/shadcn/avatar";
import { api } from "@shared-server/api";
import { cn } from "@shared-utils/ui";

interface ADUserAvatarProps {
  // For most users the userPrincipalName is the email eg. jokje@leo-pharma.com
  imagePath?: string;
  userPrincipalName?: string;
  size: "micro" | "small";
  className?: string;
}

const variants = cva("w-7 h-7", {
  variants: {
    size: {
      micro: "w-5 h-5",
      small: "w-7 h-7",
    },
  },
});

export const ADUserAvatar = (props: ADUserAvatarProps) => {
  const imagePath =
    props.imagePath ??
    (props.userPrincipalName
      ? api.msGraph.profilePhoto.useQuery({
          userPrincipalName: props.userPrincipalName,
        }).data
      : null);

  return (
    <Avatar
      className={cn(
        "flex items-center justify-center bg-primary",
        props.className,
        variants({ size: props.size }),
      )}
    >
      {imagePath ? (
        <AvatarImage className="bg-primary" src={imagePath} alt="@shadcn" />
      ) : (
        <User size={props.size === "small" ? 12 : 10} />
      )}
    </Avatar>
  );
};
