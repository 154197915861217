import { datadogRum } from "@datadog/browser-rum";
import { type AppRouter } from "@shared-server/api/app-router";
import { isLocalDev } from "@shared-utils/is-local-dev";
import { env } from "@shared/env.mjs";
import { QueryClient } from "@tanstack/react-query";
import { httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import { z } from "zod";

/**
Get trpc links for the client
*/
const trpcClientErrorSchema = z.object({
  result: z.object({
    data: z.object({
      code: z.string(),
      httpStatus: z.number(),
      path: z.string(),
    }),
  }),
});

type TRPCClientError = z.infer<typeof trpcClientErrorSchema>;

export const getTrpcLinks = (path: string) => {
  return [
    loggerLink({
      logger: isLocalDev()
        ? // If the code is running in a local development environment, the default logger is used.
          undefined
        : // If the code is running in a deployed environment, the logger is overridden to log only TRPCClientErrors.
          (opts) => {
            // Check if the payload is the shape of a TRPCClientError
            if (trpcClientErrorSchema.safeParse(opts).success) {
              const errorData = opts as TRPCClientError;
              datadogRum.addError(
                new Error(
                  `${errorData.result.data.path} | ${errorData.result.data.code}`,
                ),
                (opts as TRPCClientError).result.data,
              );
            }
          },
      enabled: () => true,
    }),
    httpBatchLink({
      url: `${getBaseUrl()}${path}`,
    }),
  ];
};

/**
Shared React Query client provider
*/

export const getQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // Disable retrying queries since this delays the UI updates
        retry: false,
      },
    },
  });
};

/**
 * Returns the base URL for the TRPC provider based on the environment.
 * If the code is running in the browser, it returns an empty string to use a relative URL.
 * If the code is running in a development server, it returns the localhost URL with the port number specified in the environment variables.
 * @returns {string} The base URL for the TRPC provider.
 */
const getBaseUrl = () => {
  if (typeof window !== "undefined") return ""; // browser should use relative url
  return `http://localhost:${env.FRONTEND_PORT}`; // dev SSR should use localhost
};

export const api = createTRPCReact<AppRouter>();

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;
